import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import ActionsListingCard from "./ActionsListingCard";
import NewActionCard from "./NewActionCard";

import styles from "./styles";

const ActionsExpansionTile = ({ classes, listing, actions, index, expanded, handleExpand }) => {

    return (
        <ExpansionPanel
            className={classes.listingsExpPanel}
            expanded={expanded === 'panel' + index}
            onChange={handleExpand('panel' + index)}
            id={'panel' + index}
            style={!(expanded === 'panel' + index) ? { borderBottom: "none" } : {}}
        >
            <ExpansionPanelSummary
                className={classes.ActionExpTile}
                expandIcon={<ExpandMoreIcon />}
                aria-controls={"panel" + index + "bh-content"}
                id={"panel" + index + "bh-header"}
            >
                <ActionsListingCard
                    listing={listing}
                    actionCount={actions.length}
                />
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.expansionPanelDetails}>
                <NewActionCard actions={actions} expPanel={'panel' + index} isExpanded={expanded === 'panel' + index} />
            </ExpansionPanelDetails>
        </ExpansionPanel >
    );
}

ActionsExpansionTile.propTypes = {
    listing: PropTypes.object,
    classes: PropTypes.object,
    actions: PropTypes.array,
    index: PropTypes.number,
    expanded: PropTypes.any,
    handleExpand: PropTypes.func
};

export default withStyles(styles)(ActionsExpansionTile);