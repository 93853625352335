export default theme => ({
  infoDialog: {
    "& .MuiDialog-paperWidthSm": {
      maxWidth: "340px !important"
    },
    [theme.breakpoints.down("xs")]: {
      "& .MuiDialog-paperFullWidth": {
      width: `calc(100% - 60px) !important`,
      },
      "& .MuiDialog-paper": {
      margin: "30px !important"
      }
    }
  },
  infoDataDialog: {
    padding: "10px 18px 15px",
    color: "rgba(0, 0, 0, 0.54)",

    "& .MuiButtonBase-root": {
      display: "flex",
      marginLeft: "auto",
      padding: 0
    },
    "& .MuiSvgIcon-root": {
      fontSize: "1rem"
    }
  },
  infoWrapper: {
    wordBreak: "break-all",
  },
  infoData: {
    marginTop: "-8px"
  }
});
