import React, { useContext, useState } from "react";
import PageContainer from "@clients-modules/react/lib/components/PageContainer";
import Loading from "@clients-modules/react/lib/components/Loading";
import ActionsError from "./components/ActionsError";
import ActionsLayout from "./components/ActionsLayout";
import ActionsNotFound from "./components/ActionsNotFound";
import ActionsContext from "../../contexts/ActionsContext";
import ListingsContext from "../../contexts/ListingsContext";
import { usePageViewTracker } from "@clients-modules/react/lib/hooks/analytics";
import { Grid, Typography } from "@material-ui/core";
import Fuse from "fuse.js";
import { isEmpty } from "lodash";
import ListingFilter from "../Listings/components/ListingFilter";
import withStyles from "@material-ui/core/styles/withStyles";
import StatusFilterComponent from "./components/StatusFilterComponent";
import styles from "./styles";
import SelectedStatusesComponent from "./components/StatusFilterComponent/SelectedStatusesComponent";
import StatusFilterContext from "../../contexts/StatusFilterContext";

const Actions = ({ classes }) => {
  usePageViewTracker("actions");

  const filterOptions = {
    // Determines which property to match on
    keys: ["sortKey"],
    // Determines how perfect a match should be (0.0 = perfect)
    threshold: 0.3
  };
  const [addressFilter, setAddressFilter] = useState("");
  const { loading, error, listings } = useContext(ListingsContext);
  const {
    getActionCardsByListingId,
    getActionCardCountByListingId,
    sourceComponent
  } = useContext(ActionsContext);
  const { selectedItems,handleSelectedItems,listingStatuses,itemsInMenu, setItemsInMenu,handleSelectedItemsList,setItemsInMenuList } = useContext(StatusFilterContext);

  const getStatus = list => {
    handleSelectedItems([...list]);
    setItemsInMenuList([...listingStatuses.sort()]);
  };

  if(sourceComponent === "listings")
  {
    handleSelectedItemsList([]);
    setItemsInMenuList([...listingStatuses.sort()]);

  }
  
  const getFilteredListings = (filter, listings) => {
    const fuse = new Fuse(listings, filterOptions);
    return fuse.search(filter);
  };

  const handleFilter = (e) => {
    if (/\S/.test(e.target.value) || (e.target.value).length === 0) {
      var addrFilterVal = (e.target.value).replace(/\s{2,}/g, ' ');
      setAddressFilter(addrFilterVal);
    }
  }


  let listContent = null;
  let filteredListings = listings;
  let filterStatusAry;

  if (addressFilter || selectedItems.length) {
    filterStatusAry = selectedItems.map(sitem => sitem.toLowerCase());
    if (addressFilter) {
      filteredListings = getFilteredListings(addressFilter, listings);
      if (selectedItems.length > 0) {
        filteredListings = filteredListings.filter(
          listingObj => filterStatusAry.indexOf(listingObj.status) > -1
        );
      }
    } else {
      if (selectedItems.length > 0) {
        filteredListings = listings.filter(
          listingObj => filterStatusAry.indexOf(listingObj.status) > -1
        );
      }
    }
  }

  if (loading) {
    listContent = <Loading />;
  } else if (error) {
    listContent = <ActionsError message={error.message} />;
  } else if (isEmpty(listings)) {
    listContent = <ActionsNotFound />;
  } else if ((addressFilter || selectedItems.length) && isEmpty(filteredListings)) {
    listContent = (
      <Typography
        className={classes.noMatches}
        component="div"
        variant="h6"
        color="textSecondary"
      >
        {
          addressFilter ? (selectedItems.length > 0 ? `No matches found` : `No matches for "${addressFilter}"`) : <ActionsNotFound />

        }
      </Typography>)
  } else {
    listContent = (
      <div>
        <ActionsLayout
          getActionCardsByListingId={getActionCardsByListingId}
          getActionCardCountByListingId={getActionCardCountByListingId}
          listings={filteredListings}
        />
      </div>
    );
  }
  return (
    <PageContainer className={classes.gridContainer}>
      <Grid alignItems="center" container>
        <Grid item xs={12} md={6}>
          <Typography component="h1" variant="h4">
            Actions
          </Typography>
        </Grid>
        <Grid item xs={12} md={3} className={classes.statusFilterOrder}>
          <StatusFilterComponent
                  listingStatuses={listingStatuses}
                  getStatus={getStatus}
                  itemsInMenu ={itemsInMenu}
                  setItemsInMenu = {setItemsInMenu}
                  selectedItems ={selectedItems}
                  handleSelectedItems ={handleSelectedItems}
          ></StatusFilterComponent>
        </Grid>
        <Grid item xs={12} md={3}>
          <ListingFilter
            value={addressFilter}
            onChange={handleFilter}
            clear={() => setAddressFilter("")}
          />
        </Grid>
      </Grid>

      <SelectedStatusesComponent selectedItems={selectedItems}  listingStatuses={listingStatuses} 
      handleSelectedItems={handleSelectedItems} itemsInMenu={itemsInMenu} setItemsInMenu ={setItemsInMenu}
      />
      
      <div>{listContent}</div>
    </PageContainer>
  );
};

export default withStyles(styles)(Actions);
