export default theme => ({
  listingInfo: {
    width: "100%"
  },
  listingTitleContainer: {
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      padding: `0 ${theme.spacing(2)}px`
    }
  },
  listingStatus: {
    textAlign: "right",
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(2),
      textAlign: "left"
    }
  },
  section: {
    width: "100%"
  },
  sectionHeading: {
    backgroundColor: "#f4f4f4",
    padding: `${theme.spacing()}px ${theme.spacing(2)}px`
  },
  sectionContent: {
    padding: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0)
    }
  },
  vendorContent: {
    display: "flex",
    justifyContent: "space-between"
  },
  vendorAvatar: {
    margin: 16,
    color: "#FFF"
  },
  vendorList: {
    paddingTop: 0,
    paddingBottom: 0
  },
  vendorListFooter: {
    paddingTop: theme.spacing(),
    width: "100%",
    textAlign: "center"
  },
  vendorDropdown: {
    marginTop: theme.spacing(3)
  },
  listingPhoto: {
    backgroundPosition: "center",
    backgroundSize: "cover",
    marginBottom: 20,
    minHeight: 400,
    maxWidth: 800,
    [theme.breakpoints.down("md")]: {
      minHeight: 250
    }
  },
  linkIcon: {
    color: "rgba(0, 0, 0, 0.54)"
  },
  alignIcon: {
    marginRight: 11
  },
  listingDetailContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    minHeight: 200,
    position: "relative"
  },
  vendorTextActive: {
    color: "green"
  },
  vendorTextInactive: {
    color: "#d62d2d"
  },
  listItemTextMobile: {
    maxWidth: "85%",
    overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      [theme.breakpoints.down("sm")]: {
        maxWidth: "68%",
      }    
  },
  menuActions: {
    display: "flex",
    alignItems: "center",
    "& .MuiChip-root": {
      marginRight: "1rem",
      [theme.breakpoints.down("sm")]: {
        marginRight: 0
      }
    }
  },
   infoDialog: {
    "& .MuiDialog-paperWidthSm": {
      maxWidth: "340px !important"
    },
    [theme.breakpoints.down("xs")]: {
      "& .MuiDialog-paperFullWidth": {
      width: `calc(100% - 60px) !important`,
      },
      "& .MuiDialog-paper": {
      margin: "30px !important"
      }
    }
  },
  infoDataDialog: {
      padding: "10px 18px 15px",
      color: "rgba(0, 0, 0, 0.54)",

      "& .MuiButtonBase-root": {
        display: "flex",
        marginLeft: "auto",
        padding: 0
      },
      "& .MuiSvgIcon-root": {
        fontSize: "1rem"
      }
  }
});
