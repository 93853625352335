import React from 'react'

import {
  Grid,
  Divider,
  Button,
  Link
} from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import withStyles from "@material-ui/core/styles/withStyles";
import styles from "./styles";



const SelectedStatusesComponent = ({
classes,
listingStatuses,
selectedItems, handleSelectedItems ,itemsInMenu, setItemsInMenu 
})=>{



  const clearAll = () => {
    handleSelectedItems([]);
    setItemsInMenu(listingStatuses.sort());
  };


 const removeItem = item => {
    var tempItems = selectedItems;
    tempItems.splice(selectedItems.indexOf(item), 1);
    handleSelectedItems([...tempItems.sort()]);
    if (itemsInMenu.indexOf(item) === -1) {
      var tempItemsMenu = itemsInMenu;
      tempItemsMenu.push(item);
      setItemsInMenu(tempItemsMenu.sort());
    }
  };


return(
<Grid>
 <Divider className={classes.gridDivider} />
  { selectedItems && selectedItems.length > 0 && (
        <Grid
          alignItems="center"
          container
          spacing={0}
          className={classes.statusBlock}
        >
          <Grid item  className={classes.statusGrid}>
            {selectedItems.length > 1 && (
                <Link
                  size="small"
                  className={classes.gridButton}
                  onClick={clearAll}
                  component="button"
                >
                  CLEAR ALL FILTERS
                </Link>
            )}
            {selectedItems.map(status => {
              return (
                <Button size="small" key={status} onClick={() => {
                  removeItem(status);
                }}>
                  {status}
                  <ClearIcon className={classes.clearIcon}/>
                </Button>
              );
            })}

          </Grid>
        </Grid>
      )}
</Grid>
);
};

export default withStyles(styles)(SelectedStatusesComponent);