import gql from 'graphql-tag';

export default gql`
  mutation hideListingActions($activityIds: [ID!],$listingId: ID!) {
    hideListingActions: hideListingActions(activityIds: $activityIds,listingId: $listingId) {
      successActivityIds
      failedActivityIds
      referenceCode
      statusCode
    }
  }
`;
