import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@material-ui/core";
import { useMutation } from "react-apollo-hooks";

import ActionButton from "@clients-modules/react/lib/components/ActionButton";
import { listingActionsCache, listingTimelineCache } from "../../helpers/cache";
import ActionsContext from "../../contexts/ActionsContext";
import ljhAnalytics from "@clients-modules/js/ljhAnalytics";
import mutationHideListingAction from "../../graphql/mutationHideListingAction";
import mutationHideListingActions from "../../graphql/mutationHideListingActions";
import NotificationContext from "../../contexts/NotificationContext";
import AuthContext from "@clients-modules/react/lib/contexts/AuthContext";

const HideConfirmDialog = ({ cards, onClose }) => {
  const { showNotification } = useContext(NotificationContext);
  const { hideActivityId, sourceComponent, handleActionCards } = useContext(ActionsContext);
  const [loading, setLoading] = useState(false);
  const { email } = useContext(AuthContext);

  const popUpHeading = (sourceComponent) => {
    if (sourceComponent === "actions" && (cards && cards[0].status === "pending"))
      return "Hide from actions?";
    else if (sourceComponent === "timelineListing")
      return "Hide from timeline?";
    else
      return "";
  }

  var hasMultipleCards;
  hasMultipleCards = cards && cards.length > 1;

  const popUpMessage = (sourceComponent) => {
    if (sourceComponent === "actions" && (cards && cards[0].status === "pending"))
      return hasMultipleCards ? "Hiding will remove these selected cards from your outstanding actions. These cards will appear in the Hidden tab in the Listing’s timeline."
        : <React.Fragment>Hiding <strong>{cards[0].title}</strong> will remove this card from your outstanding actions. This card will appear in the Hidden tab in the Listing’s timeline.</React.Fragment>
    else if (cards && sourceComponent === "timelineListing")
      return <React.Fragment>Hiding <strong>{cards[0].title}</strong> will remove this card from the timeline. This card will appear in the Hidden tab in the Listing’s timeline.</React.Fragment>
    else
      return "";
  }

  var mutationName = hasMultipleCards ? mutationHideListingActions : mutationHideListingAction
  const hideListingAction = useMutation(mutationName);

  const onHideConfirmed = async () => {
    setLoading(true);
    if (sourceComponent === "actions")
      handleActionCards(true);
    try {
      var successActivityIds;
      var idsAry = [], listingId = cards[0].listingId, titleAry = [];
      cards.map((cardObj) => {
        idsAry.push(cardObj.id);
        titleAry.push(cardObj.title);
      });

      await hideListingAction({
        variables: hasMultipleCards ? {
          activityIds: idsAry,
          listingId: listingId
        } : {
            activityId: idsAry[0]
          },
        update: (
          cache,
          {
            data
          }
        ) => {
          var updateCard;

          switch (sourceComponent) {
            case "actions":
              const actionsCache = listingActionsCache(cache, listingId);
              updateCard = actionsCache.updateCard;
              break;
            case "timelineListing":
              const timelineCache = listingTimelineCache(cache, listingId);
              updateCard = timelineCache.updateCard;
              break;
            default:
              break;
          }
          if (hasMultipleCards) {
            successActivityIds = data.hideListingActions.successActivityIds;
            successActivityIds.map((id) => {
              var hiddenCardObj = cards.find((cardObj) => cardObj.id === id);
              hiddenCardObj.hideable = false;
              hiddenCardObj.status = "hidden";
              updateCard(hiddenCardObj);
            }
            )
          } else {
            updateCard(data.hideListingAction.card);
          };
        }
      });

      if (hasMultipleCards)
        ljhAnalytics.track("Listing action suppressed", {
          activityIds: successActivityIds,
          listingId,
          title: titleAry,
          email
        });
      else
        ljhAnalytics.track("Listing action suppressed", {
          activityId: idsAry[0],
          listingId,
          title: titleAry[0],
          email
        });

      showNotification({
        message: hasMultipleCards ? "Cards successfully hidden." : "Card successfully hidden.",
        type: "success"
      });
      hideActivityId(idsAry);
    } catch (e) {
      showNotification &&
        showNotification({
          message: "Action has failed, please try again.",
          type: "error"
        });
    }
    if (sourceComponent === "actions")
      handleActionCards(false);

    setLoading(false);
    onClose();
  };

  return (
    <Dialog open={true} onClose={onClose}>
      <DialogTitle>{popUpHeading(sourceComponent)}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {popUpMessage(sourceComponent)}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <ActionButton onClick={onClose}>Cancel</ActionButton>
        <ActionButton onClick={onHideConfirmed} loading={loading}>
          Confirm
        </ActionButton>
      </DialogActions>
    </Dialog>
  );
};

HideConfirmDialog.propTypes = {
  cards: PropTypes.array,
  onClose: PropTypes.func,
};

export default HideConfirmDialog;
