import React, { useContext } from "react";
import PropTypes from "prop-types";
import { CardActions, Grid, Button } from "@material-ui/core";

import { ACTION_DIALOGS } from "../../constants";
import ActionsContext from "../../contexts/ActionsContext";

const StandardCardLinks = ({ cardsData, links, publishable, hideable ,view }) => {
  const { toggleDialog } = useContext(ActionsContext);

  const renderPrimaryLinks = () => {
    let primaryLinks = [];

    if (publishable) {
      primaryLinks = (
        <React.Fragment>
          <Button
            onClick={() =>
              toggleDialog(ACTION_DIALOGS["publish-confirm"], cardsData)
            }
            color="secondary"
          >
            {cardsData.length > 1 ? (view && view==="mobile"? "Publish":"Publish Selected"): "Publish"}
          </Button>
          {hideable && (
            <Button
              onClick={() =>
                toggleDialog(ACTION_DIALOGS["hide-confirm"], cardsData)
              }
              color="secondary"
            >
              {cardsData.length > 1 ? (view && view==="mobile"?"Hide":"Hide Selected"): "Hide"}
            </Button>
          )}

          {links.length > 0 && (
            <Button href={links[0].url} target="_blank" color="secondary"
            >
              {links[0].text}
            </Button>)}
        </React.Fragment>
      );
    } else {
      primaryLinks = links.map(({ text, url }) => (
        <Button key={text} href={url} target="_blank" color="secondary">
          {text}
        </Button>
      ));
    }

    return <Grid item>{primaryLinks}</Grid>;
  };

  return <CardActions>{renderPrimaryLinks()}</CardActions>;
};

StandardCardLinks.propTypes = {
  cardsData: PropTypes.any,
  cardType: PropTypes.string,
  editable: PropTypes.bool,
  hideable: PropTypes.bool,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      url: PropTypes.string
    })
  ),
  publishable: PropTypes.bool,
  status: PropTypes.string,
  view: PropTypes.string
};

export default StandardCardLinks;
