import React from "react";
import {
  IconButton,
  Menu,
  MenuItem,
  withStyles
  } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import styles from "./styles";

const ListingVendorActions = ({
  classes,
  emailId,
  removeVendor,
  updatedByAgentEmail, 
  updatedByTimestamp,
  handleInfoDialog,
  sourceCrm
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        className={classes.iconButton}
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        { !sourceCrm &&
          <MenuItem
            onClick={() => {
              removeVendor(emailId);
              handleClose();
            }}
          >
            DELETE
          </MenuItem>
        }
        <MenuItem
          onClick={() => {
            handleInfoDialog(updatedByAgentEmail,updatedByTimestamp);
            handleClose();
          }}
        >
          INFO
        </MenuItem>
      </Menu>
    </div>
  );
};

export default withStyles(styles)(ListingVendorActions);