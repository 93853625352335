import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import size from "lodash/size";
import {
  Avatar,
  Chip,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Typography
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import ListingVendorActions from  "../ListingInfo/ListingVendorActions";
import EmptyState from "@clients-modules/react/lib/components/EmptyState";

import styles from "./styles";

const ListingVendors = ({
  classes,
  vendors,
  disableActions,
  onRemoveVendor,
  handleInfoDialog
}) => {
  const renderVendorInfo = (email, lastActive, sourceCrm) => {
    if (email) {
      return (
        <React.Fragment>
          <Typography color="textSecondary" className={sourceCrm?classes.listItemTextMobile: ""} noWrap>
            {email}
          </Typography>
          {lastActive ? (
            <Typography
              className={classes.vendorTextActive}
              color="textSecondary"
            >
              last active {moment(lastActive).fromNow()}
            </Typography>
          ) : (
            <Typography
              className={classes.vendorTextInactive}
              color="textSecondary"
            >
              never logged in
            </Typography>
          )}
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <Typography color="textSecondary" className={sourceCrm?classes.listItemTextMobile: ""}>
          No email address available.
        </Typography>
        <Typography color="textSecondary" className={sourceCrm?classes.listItemTextMobile: ""}>
          Click on the 'Share With Vendor' button below and enter an email to
          share this listing.
        </Typography>
      </React.Fragment>
    );
  };

  return (
    <div className={classes.section}>
      <div className={classes.sectionHeading}>
        <Typography variant="subtitle1">VENDORS</Typography>
      </div>
      <div className={classes.sectionContent}>
        {size(vendors) === 0 ? (
          <EmptyState
            iconName="users"
            title="No vendors"
            content="Click on the 'Share With Vendor' button below to share this listing with a vendor."
          />
        ) : (
          <List className={classes.vendorList}>
            {vendors.map(
              ({ name, email, lastActive, pictureUrl, sourceCrm, sharedByAgentEmail, sharedTimestamp }) => (
                <ListItem key={name} alignItems="center" disableGutters>
                  <ListItemAvatar>
                    {pictureUrl ? (
                      <Avatar src={pictureUrl} />
                    ) : (
                      <Avatar className={classes.vendorAvatar}>
                        {name ? name.charAt(0).toUpperCase() : "V"}
                      </Avatar>
                    )}
                  </ListItemAvatar>
                  <ListItemText
                    primary={name}
                    secondary={renderVendorInfo(email, lastActive, sourceCrm)}
                    className={sourceCrm?classes.listItemTextMobile:""}
                  />
                  <ListItemSecondaryAction className={classes.menuActions}>
                    {sourceCrm &&
                      <Chip label="CRM" className={classes.chip} />
                    }
                    <ListingVendorActions
                      emailId={email}
                      removeVendor={onRemoveVendor}
                      updatedByAgentEmail={sharedByAgentEmail}
                      updatedByTimestamp={sharedTimestamp}
                      handleInfoDialog={handleInfoDialog}
                      sourceCrm={sourceCrm}
                    />
                  </ListItemSecondaryAction>
                </ListItem>

              )
            )}
      
          </List>
        )}
      </div>
    </div>
  );
};

ListingVendors.propTypes = {
  classes: PropTypes.object,
  vendors: PropTypes.array
};

ListingVendors.defaultProps = {
  classes: {},
  vendors: []
};

export default withStyles(styles)(ListingVendors);
