export default theme => ({
  gridContainer: {
    padding: theme.spacing(),
    margin: "16px"
  },
  gridDivider: {
    marginTop: 2,
    marginBottom: 15
  },
  statusFilter: {
    padding: "5px"
  },
  disabledLink: {
    pointerEvents: "none",
    borderStyle: "none",
    backgroundColor: "#fafafa",
    focus: "none"
  },
  gridSpan: {
    color: "gray",
    marginLeft: "4px"
  },
  gridButton: {
    color: "gray",
    marginLeft: "4px"
  },
  noMatches: {
    left: "50%",
    transform: "translate(-50%)",
    position: "absolute"
  },
  statusFilterOrder:{
    [theme.breakpoints.down("sm")]:{
      order : 2,
      marginBottom : "-25px",
      marginTop: "-17px"
    },
    [theme.breakpoints.down("md")]: {
      marginTop: 12
    },
    [theme.breakpoints.between("xs", "sm")]: {
      marginTop: 0
    }
  }
});
