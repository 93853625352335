export default theme => ({
  subHeading: {
    marginBottom: "36px",
  },
  mobileView: {
    [theme.breakpoints.up("sm")]: {
      display: "none"
    }
  },
  desktopView: {
    [theme.breakpoints.down("xs")]: {
      display: "none"
    }
  },
  listingItem: {
    padding: "5px !important",
    width: "100%"
  },
  cardsGrid: {
    justifyContent: "stretch",
    padding: "0 4px"
  },
  [theme.breakpoints.down("xs")]: {
    paddingLeft: 0
  },
  ActionsListViewContainer: {
    margin: "auto",
    boxShadow: "0 1px 3px 0 rgba(0,0,0,0.2), 0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12)",
    padding: "2% !important",
    [theme.breakpoints.down("xs")]: {
      boxShadow: "none",
      content: "Outstanding Actions"
    },
  },
renderingLoader:{
marginTop: "100px", 
height: 40, 
position: "relative", 
left: "-50%",
"& p":{
display:"none"
}
}
});
