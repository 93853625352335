import React from "react";
import { IconButton, Typography, Dialog } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";
import moment from "moment";

const InfoDialog = ({ onClose, classes, emailId, timeStamp, emailText}) => {

  const displayText = (emailId, timeStamp, emailText) =>{

    if (emailId && timeStamp)
    {
      return emailText?emailText+"by":"Last modified by"
    }else if(timeStamp)
    {
      return emailText+"on"
    }
    return "No email has been sent"

  }

  return (
    <Dialog
      open={true}
      onClose={onClose}
      fullWidth
      className={classes.infoDialog}
    >
      <div className={classes.infoDataDialog} >
        <IconButton onClick={onClose} >
          <CloseIcon />
        </IconButton>
        <Typography className={classes.infoData}>{ displayText(emailId, timeStamp, emailText) }</Typography>
        <div className={classes.infoWrapper}>
          <Typography color="primary" >{ emailId }</Typography>
          { timeStamp &&
          <Typography>
            { moment(timeStamp).format("ddd D MMM Y LT") }
          </Typography>                                    
          }
        </div>
      </div>
    </Dialog>
  );
};

export default withStyles(styles)(InfoDialog);
