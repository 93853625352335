import React, { useContext } from "react";
import ReactDOM from "react-dom";
import { Route, Switch, Redirect, HashRouter } from "react-router-dom";
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import AuthContext, {
  AuthProvider
} from "@clients-modules/react/lib/contexts/AuthContext";
import DataContext, {
  DataProvider
} from "@clients-modules/react/lib/contexts/DataContext";
import App from "@clients-modules/react/lib/containers/App";
import EmptyState from "@clients-modules/react/lib/components/EmptyState";
import PageContainer from "@clients-modules/react/lib/components/PageContainer";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";

import Actions from "./pages/Actions";
import Listings from "./pages/Listings";
import ListingDetail from "./pages/ListingDetail";
import Services from "./pages/Services";
import { unregister } from "./registerServiceWorker";
import Staff from "./pages/Staff";

import ActionsContext, {
  ActionsContextProvider
} from "./contexts/ActionsContext";
import { ListingsContextProvider } from "./contexts/ListingsContext";
import { StaffContextProvider } from "./contexts/StaffContext";
import { NotificationContextProvider } from "./contexts/NotificationContext";

import theme from "./theme";
import config from "./config";
import {
  SCOPE_ACTIONS,
  SCOPE_LISTINGS,
  SCOPE_STAFF
} from "@clients-sites/ljh-platform/src/constants/index";
import { StatusFilterContextProvider } from "./contexts/StatusFilterContext";

const muiTheme = theme();

const ListingDetailRouter = ({ match }) => {
  return (
    <Switch>
      <Route path={`${match.path}/:tab`} component={ListingDetail} />
      <Redirect exact from={match.path} to={`${match.path}/timeline`} />
    </Switch>
  );
};
const ListingsRouter = ({ match }) => {
  return (
    <React.Fragment>
      <Route exact path={match.path} component={Listings} />
      <Route
        path={`${match.path}/:listingId`}
        component={ListingDetailRouter}
      />
    </React.Fragment>
  );
};

const RoleEmpty = () => {
  const { currentOffice } = useContext(AuthContext);
  return (
    <PageContainer title="Something went wrong">
      <EmptyState
        iconName="exclamation-circle"
        content={[
          <Typography key={`${currentOffice.officeName}-no-role-${0}`}>
            {`We are unable to detect your role for ${currentOffice.officeName}, `}
            please contact support at&nbsp;
            <Link
              href="mailto:support@ljhooker.com?Subject=Platform%20Support%20Request:%20Role%20Undetected"
              target="_top"
            >
              support@ljhooker.com to have this resolved.
            </Link>
          </Typography>
        ]}
      />
    </PageContainer>
  );
};

const InactiveProfile = () => {
  const { referenceCode } = useContext(AuthContext);
  return (
    <PageContainer title="Something went wrong">
      <EmptyState
        iconName="exclamation-circle"
        content={[
          <Typography>
            Sorry, we are having problems recognising your credentials.<br />Please contact&nbsp;
            <Link
              href={"mailto:support@ljhooker.com?Subject=Platform%20Support%20Request:%20Undetected%20Credentials%20" + referenceCode}
              target="_top"
            >
              support@ljhooker.com
            </Link>
            &nbsp;and quote this code {referenceCode}
          </Typography>
        ]}
      />
    </PageContainer>
  );
};

const EmptyScope = () => {
  const { currentOffice } = useContext(AuthContext);
  return (
    <PageContainer title="Something went wrong">
      <EmptyState
        iconName="exclamation-circle"
        content={[
          <Typography key={`${currentOffice.officeName}-empty-scope-${0}`}>
            Something went wrong, please contact support at&nbsp;
            <Link
              href="mailto:support@ljhooker.com?Subject=Platform%20Support%20Request:%20Tenant%20Undetected"
              target="_top"
            >
              support@ljhooker.com to have this resolved.
            </Link>
          </Typography>
        ]}
      />
    </PageContainer>
  );
};

const getAllowedRoutesByProfile = (authProps, actionCardCount) => {
  var routesArray = [];
  if (!authProps.loginSuccess) {
    routesArray = [
      {
        name: "_",
        path: "/",
        component: InactiveProfile,
        exact: true
      }
    ];
  } else if (!authProps.currentRole) {
    console.log("Error! user scope is empty");
    routesArray = [
      {
        name: "_",
        path: "/",
        component: RoleEmpty,
        exact: true
      }
    ];
  }
  else if (authProps.currentScope.length === 0) {
    routesArray = [
      {
        name: "_",
        path: "/",
        component: EmptyScope,
        exact: true
      }
    ];
  } else {

    const scope = authProps.currentScope;
    if (scope.includes(SCOPE_ACTIONS)) {
      routesArray = [{
        name: "Actions",
        path: "/",
        component: Actions,
        exact: true,
        badgeContent: actionCardCount
      }];
    }
    if (scope.includes(SCOPE_LISTINGS)) {
      routesArray.push({
        name: "Listings",
        path: "/listings",
        component: ListingsRouter
      });
    }
    routesArray.push(
      {
        name: "Services",
        path: "/services",
        component: Services
      }
    );
    if (scope.includes(SCOPE_STAFF)) {
      routesArray.push({
        name: "Staff",
        path: "/staff",
        component: Staff
      });
    }
  }
  return routesArray
};

ReactDOM.render(
  <DataProvider url={`${config.appsyncUrl}/graphql`}>
    <DataContext.Consumer>
      {({ client }) => (

        <MuiThemeProvider theme={muiTheme}>
          <AuthProvider client={client} config={config}>
            <NotificationContextProvider>
              <ActionsContextProvider>
                <ListingsContextProvider>
                  <StaffContextProvider>
                    <StatusFilterContextProvider>
                      <HashRouter basename="/">
                        <ActionsContext.Consumer>
                          {({ actionCardCount }) => (
                            <AuthContext.Consumer>
                              {authProps => {
                                return (
                                  <App
                                    routes={getAllowedRoutesByProfile(
                                      authProps,
                                      actionCardCount
                                    )}
                                    version={config.version}
                                    {...authProps}
                                  />
                                );
                              }}
                            </AuthContext.Consumer>
                          )}
                        </ActionsContext.Consumer>
                      </HashRouter>
                    </StatusFilterContextProvider>
                  </StaffContextProvider>
                </ListingsContextProvider>
              </ActionsContextProvider>
            </NotificationContextProvider>
          </AuthProvider>
        </MuiThemeProvider>
      )}
    </DataContext.Consumer>
  </DataProvider>,
  document.getElementById("root")
);

// Unregister service worker.
unregister();
