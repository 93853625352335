import React from "react";
import {
  IconButton,
  Menu,
  MenuItem,
  withStyles
  } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import styles from "./styles";

const MenuItems = ({
  classes,
  disableActions,
  inspection,
  toggleEditInspectionDialog,
  toggleInfoInspectionDialog
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        className={classes.iconButton}
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          disabled={disableActions}
          onClick={() => {
            toggleEditInspectionDialog(inspection);
            handleClose();
          }}
        >
          EDIT
        </MenuItem>
        { inspection.updatedByAgentEmail &&
          <MenuItem
            onClick={() => {
              toggleInfoInspectionDialog(inspection);
              handleClose();
            }}
          >
            INFO
          </MenuItem>
        }
      </Menu>
    </div>
  );
};

export default withStyles(styles)(MenuItems);