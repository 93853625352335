import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  noActionsContainer: {
    marginTop: 28,
    [theme.breakpoints.down('sm')]: {
      marginTop: 20
    }
  },
  noActionsImage: {
    marginBottom: 28,
    width: "17%",
    [theme.breakpoints.down('sm')]: {
      width: "20%",
      marginBottom: 20,
    },
    [theme.breakpoints.down('xs')]: {
      width: "30%",
      marginBottom: 20,
    }
  }
}));
const ActionsNotFound = () => {
  const classes = useStyles();
  return (
    <Grid container alignItems="center" className={classes.noActionsContainer} justify="center">
      <Grid item align="center">
        <img src="https://assets.ljhooker.com/cache/69a86c5fe834ed6a82987313fe0a09356014ea91.png" className={classes.noActionsImage} alt="Noactions" />
        <Typography color="textSecondary" variant="h6" gutterBottom>
          You're all up to date!
      </Typography>
        <Typography component="div">There are no actions required at this time.</Typography>
      </Grid>
    </Grid>
  );
};
export default ActionsNotFound;
