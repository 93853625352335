import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Tooltip
} from "@material-ui/core";
import { useMutation } from "react-apollo-hooks";
import ActionButton from "@clients-modules/react/lib/components/ActionButton";
import ActionsContext from "../../contexts/ActionsContext";
import ljhAnalytics from "@clients-modules/js/ljhAnalytics";
import mutationPublishListingActions from "../../graphql/mutationPublishListingActions";
import mutationPublishListingAction from "../../graphql/mutationPublishListingAction";
import NotificationContext from "../../contexts/NotificationContext";
import { listingActionsCache, listingTimelineCache } from "../../helpers/cache";
import AuthContext from "@clients-modules/react/lib/contexts/AuthContext";

const PublishConfirmDialog = ({ cards, onClose }) => {
  const [notifyVendor, setNotifyVendor] = useState(false);
  const { showNotification } = useContext(NotificationContext);
  const { hideActivityId, sourceComponent, handleActionCards } = useContext(ActionsContext);
  const [loading, setLoading] = useState(false);
  const { email } = useContext(AuthContext);
  var hasMultipleCards;
  hasMultipleCards = cards && cards.length > 1;

  var mutationName = hasMultipleCards ? mutationPublishListingActions : mutationPublishListingAction;

  const publishListingActions = useMutation(mutationName);

  const onPublishConfirmed = async () => {
    setLoading(true);
    if (sourceComponent === "actions")
      handleActionCards(true);
    try {
      var successActivityIds;
      var idsAry = [], listingId = cards[0].listingId, titleAry = [];
      cards.map((cardObj) => {
        idsAry.push(cardObj.id);
        titleAry.push(cardObj.title);
      });

      await publishListingActions({
        variables: hasMultipleCards ? {
          activityIds: idsAry,
          listingId,
          notifyVendor
        } : {
            activityId: idsAry[0],
            notifyVendor
          },
        update: (
          cache,
          {
            data
          }
        ) => {
          var updateCard;

          switch (sourceComponent) {
            case "actions":
              const actionsCache = listingActionsCache(cache, listingId);
              updateCard = actionsCache.updateCard;
              break;
            case "timelineListing":
              const timelineCache = listingTimelineCache(cache, listingId);
              updateCard = timelineCache.updateCard;
              break;
            default:
              break;
          }
          if (hasMultipleCards) {
            successActivityIds = data.publishListingActions.successActivityIds;
            successActivityIds.map((id) => {
              var publishedCardObj = cards.find((cardObj) => cardObj.id === id);
              publishedCardObj.status = "published";
              publishedCardObj.publishable = "false";
              updateCard(publishedCardObj);
            })
          }
          else {
            updateCard(data.publishListingAction.card)
          }

        }
      });

      if (hasMultipleCards) {
        ljhAnalytics.track("Listing action published", {
          activityIds: successActivityIds,
          listingId,
          title: titleAry,
          email
        });
      } else {
        ljhAnalytics.track("Listing action published", {
          activityId: idsAry[0],
          listingId,
          title: titleAry[0],
          email
        });
      }

      showNotification({
        message: hasMultipleCards ? "Cards successfully published." : "Card successfully published.",
        type: "success"
      });
      hideActivityId(idsAry);

    } catch (e) {
      showNotification &&
        showNotification({
          message: " Action has failed, please try again.",
          type: "error"
        });
    }

    if (sourceComponent === "actions")
      handleActionCards(false);

    setLoading(false);
    onClose();
  };

  const toggleNotifyVendor = () => {
    setNotifyVendor(!notifyVendor);
  };

  return (
    <Dialog open={true} onClose={onClose}>
      <DialogTitle>Publish to vendor?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {cards ? (hasMultipleCards ? "Publishing will make these selected cards visible to the vendor." : (<React.Fragment>Publishing <strong>{cards[0].title}</strong> will make this card visible to the vendor.</React.Fragment>)) : ""}
        </DialogContentText>
        <FormControl fullWidth>
          <Tooltip
            title="Checking this box will send a push notification to all the vendors you have added to this listing"
            placement="bottom-start"
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={notifyVendor}
                  onChange={toggleNotifyVendor}
                />
              }
              label="Notify vendors"
            />
          </Tooltip>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <ActionButton onClick={onClose}>Cancel</ActionButton>
        <ActionButton onClick={onPublishConfirmed} loading={loading}>
          Confirm
        </ActionButton>
      </DialogActions>
    </Dialog>
  );
};

PublishConfirmDialog.propTypes = {
  cards: PropTypes.array,
  onClose: PropTypes.func
};

export default PublishConfirmDialog;
