"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.usePageViewTracker = void 0;

var _react = require("react");

var usePageViewTracker = function usePageViewTracker(pageName) {
  (0, _react.useEffect)(function () {
    if (!window.analytics) return;
    window.analytics.page(pageName);
  });
};

exports.usePageViewTracker = usePageViewTracker;