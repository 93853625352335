import React from "react";

const StatusFilterContext = React.createContext({});

const StatusFilterContextProvider = ({ children }) => {


  const listingStatuses = [
    "Listed",
    "Settled",
    "Conditional",
    "Unconditional",
    "Commenced"
  ];
const [selectedItems, setSelectedItems] = React.useState([]);
const [itemsInMenu, setItemsInMenu] = React.useState(listingStatuses.sort());
const [selectedItemsList, setSelectedItemsList] = React.useState([]);
const [itemsInMenuList, setItemsInMenuList] = React.useState(listingStatuses.sort());

const handleSelectedItems = (statuses) =>{
  setSelectedItems([...statuses]);
}


const handleSelectedItemsList = (statuses) =>{
  setSelectedItemsList([...statuses]);
}


return (
    <StatusFilterContext.Provider
      value={{
        selectedItemsList,
        handleSelectedItemsList,
        itemsInMenuList,
        setItemsInMenuList,
        handleSelectedItems,
        selectedItems,
        itemsInMenu,
        setItemsInMenu,
        listingStatuses
      }}
    >
{children}
</StatusFilterContext.Provider>
);
};


export default StatusFilterContext;
export { StatusFilterContextProvider };