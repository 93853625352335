import React, { useContext, useEffect } from "react";
import { Grid, Grow, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { isEmpty, size } from "lodash";
import Loading from "@clients-modules/react/lib/components/Loading";
import ActionsNotFound from "../ActionsNotFound";
import ActionsContext from "../../../../contexts/ActionsContext"
import ActionsExpansionTile from "../ActionsExpansionTile"
import styles from "./styles";
const ActionsLayout = ({
  classes,
  getActionCardCountByListingId,
  getActionCardsByListingId,
  listings
}) => {
  const [expanded, setExpanded] = React.useState(false);
  const [listingsHasActions, setListingsHasActions] = React.useState([]);
  const [visibleListingsCount, setVisibleListingsCount] = React.useState(15);
  const [loading, setLoading] = React.useState(false);

  const handleExpand = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  function _get_window_height() {
    return window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight || 0;
  }
  /**
   * Get current absolute window scroll position
   */
  function _get_window_Yscroll() {
    return window.pageYOffset ||
      document.body.scrollTop ||
      document.documentElement.scrollTop || 0;
  }
  /**
   * Get current absolute document height
   */
  function _get_doc_height() {
    return Math.max(
      document.body.scrollHeight || 0,
      document.documentElement.scrollHeight || 0,
      document.body.offsetHeight || 0,
      document.documentElement.offsetHeight || 0,
      document.body.clientHeight || 0,
      document.documentElement.clientHeight || 0
    );
  }
  function _get_scroll_percentage() {
    return Math.round((
      (_get_window_Yscroll() + _get_window_height()) / _get_doc_height()
    ) * 100);
  }
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    }
  });

  const { setComponent } = useContext(ActionsContext);
  setComponent("actions");

  if (!listings) return null;

  let listingsWithActions;
  listingsWithActions = [];
  listings.forEach(x => {
    if (getActionCardCountByListingId(x.listingId) > 0) {
      listingsWithActions.push(x);
    } else if (getActionCardCountByListingId(x.listingId) === -1) {
      listingsWithActions[0] = "Loading"
    }
  });

  if (listingsWithActions.length > 0 && (listingsHasActions.length === 0 || listingsHasActions.length !== listingsWithActions.length)) {
    setExpanded(false);
    setLoading(false);
    setListingsHasActions([...listingsWithActions]);
  }

  const handleScroll = () => {
    if (listingsWithActions.length > 15 && (visibleListingsCount < listingsWithActions.length)) {
      setLoading(true); 
      var scrollPercent = _get_scroll_percentage();
      if ((scrollPercent >= 99)) {
        if (visibleListingsCount + 15 >= listingsWithActions.length){
            setVisibleListingsCount(listingsWithActions.length)
            setLoading(false);
        }
        else
          setVisibleListingsCount(visibleListingsCount + 15);
      }

    }
      
  }

  return (
    <Grid container direction="column" spacing={1}>
      {!isEmpty(listingsWithActions) ? listingsWithActions[0] === "Loading" ? (<Loading />) : (
        <Grid item md={7} sm={10} className={classes.ActionsListViewContainer}>
          <Typography
            className={classes.subHeading}
            component="h2"
            variant="h5"
          >
            <div className={classes.desktopView}>
              Outstanding actions for all offices
              </div>
            <div className={classes.mobileView}>
              Outstanding actions
              </div>
          </Typography>
          {renderGrid(
            classes,
            getActionCardCountByListingId,
            getActionCardsByListingId,
            listingsWithActions,
            expanded,
            handleExpand,
            visibleListingsCount,
            loading,
            setLoading
          )}
        </Grid>
      ) : (
          <ActionsNotFound />
        )}
    </Grid>
  );
};
const hasNoResidentialProperties = listings => {
  if (size(listings) === 1) {
    return listings[0].isDemo;
  }
  return false;
};
const renderGrid = (
  classes,
  getActionCardCountByListingId,
  getListingActionCards,
  listings,
  expanded,
  handleExpand,
  visibleListingsCount,
  loading,
  setLoading
) => {
  return (
    <Grid container className={classes.cardsGrid} spacing={4}>
      {listings.slice(0, visibleListingsCount).map((x, index) => {
        return (
          <Grid className={classes.listingItem} key={x.listingId} item>
            {renderListing(
              classes,
              getActionCardCountByListingId,
              getListingActionCards,
              x,
              index,
              expanded,
              handleExpand
            )}
          </Grid>
        )
      })}
      {hasNoResidentialProperties(listings) && (
        <div className={classes.listingNotFoundContainer}>
          <ActionsNotFound className={classes.listingNotFound} />
        </div>
      )}
     {loading ? 
      (<div className={classes.renderingLoader}>
        <Loading /> 
      </div>)
     : null}
    </Grid>
  );
};
const renderListing = (
  classes,
  getActionCardCountByListingId,
  getListingActionCards,
  listing,
  index,
  expanded,
  handleExpand
) => {
  var actions = getListingActionCards(listing.listingId);
  actions = actions.filter(
    action => action.hideable && action.status === "pending"
  );
  return (
    actions.length > 0 && (
      <Grow in={true}>
        <Grid className={classes.listingItem} item key={listing.listingId}>
          <ActionsExpansionTile
            listing={listing}
            actions={actions}
            index={index}
            expanded={expanded}
            handleExpand={handleExpand}
          />
        </Grid>
      </Grow>
    )
  );
};
export default withStyles(styles)(ActionsLayout);