import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Avatar, Grid, Typography } from '@material-ui/core';
import { DEFAULT_LISTING_IMAGE } from "../../../../constants";
import styles from "./styles";

const ActionsListingCard = ({ classes, listing, actionCount }) => {

    return (<Grid container direction="row" className={classes.listingCardContainer}>
        <Grid container className={classes.desktopView}>
            <Grid item md={2} sm={4}>
                <div className={classes.listingImage} style={{ background: `url(${listing.photoPrimaryUrl || DEFAULT_LISTING_IMAGE}) center center / cover no-repeat` }}>
                </div>
            </Grid>
            <Grid item md={4} sm={4} className={classes.listingInfo}>
                <Typography variant="subtitle1" component="div" >
                    {listing.propertyAddress.street}
                </Typography>
                <Typography variant="body2" color="textSecondary"  >
                    {listing.propertyAddress.suburb}
                </Typography>
            </Grid>
            <Grid item md={4} sm={3} className={classes.listingInfo}>
                <Typography variant="body2" component="div">
                    {listing.statusText}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                    {listing.statusDate}
                </Typography>
            </Grid>
            <Grid item md={2} sm={1}>
                <Avatar className={classes.actionCountSection}>{actionCount}</Avatar>
            </Grid>
        </Grid>

        {/* Mobile view for actions*/}
        <Grid container className={classes.mobileView} direction="row">
            <Grid className={classes.actionsMobileView}>
                <Grid style={{ fontSize: 18 }}>{listing.propertyAddress.street}
                    <Avatar className={classes.actionCountSection}>{actionCount}</Avatar>
                </Grid>
                <Grid className={classes.propertyAddressAndDate}>{listing.propertyAddress.suburb} </Grid>
            </Grid>
            <Grid className={classes.actionsMobileView}>
                <Grid style={{ fontSize: 14 }}> {listing.statusText} </Grid>
                <Grid className={classes.propertyAddressAndDate}> {listing.statusDate}</Grid>
            </Grid>
        </Grid>
    </Grid>
    );
}
ActionsListingCard.propTypes = {
    listing: PropTypes.object,
    classes: PropTypes.object,
    actionCount: PropTypes.number
};

export default withStyles(styles)(ActionsListingCard);