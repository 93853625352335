import React from "react";
import { graphql } from "react-apollo";
import flowRight from "lodash/flowRight";
import { Button, Divider, Grid, Typography, Dialog, IconButton } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import size from "lodash/size";

import ListingShare from "./ListingShare";
import ListingVendors from "./ListingVendors";
import ListingVendorRemove from "./ListingVenderRemove";
import ListingWebsites from "./ListingWebsites";
import ListingAgents from "./ListingAgents";
import CloseIcon from "@material-ui/icons/Close";

import Error from "@clients-modules/react/lib/components/Error";
import Loading from "@clients-modules/react/lib/components/Loading";

import ljhAnalytics from "@clients-modules/js/ljhAnalytics";
import queryGetListingDetailsById from "../../graphql/queryGetListingDetailsById";
import { DEFAULT_LISTING_IMAGE } from "../../../../constants";

import styles from "./styles";
import InfoDialog from "../../../../components/ActionDialog/InfoDialog";
import moment from "moment";

class ListingInfo extends React.Component {
  state = {
    showShareWithDialog: false,
    showVendorRemoveDialog: false,
    vendorEmailToRemove: "",
    showInfoDialog: false,
    sharedByEmail: "",
    sharedByTimestamp: "",
    showDialog: false,
    infoText:""
  };

  componentDidMount() {
    ljhAnalytics.page("listings/listing");
  }

  handleVendorRemoveDialogOpen = vendorEmailToRemove => {
    this.setState({ showVendorRemoveDialog: true, vendorEmailToRemove });
  };

  handleVendorRemoveDialogClose = () => {
    this.setState({ showVendorRemoveDialog: false, vendorEmailToRemove: "" });
  };

  handleShareWithDialogOpen = () => {
    this.setState({ showShareWithDialog: true });
  };

  handleShareWithDialogClose = () => {
    this.setState({ showShareWithDialog: false });
  };

  handleInfoDialog = (email, timestamp) => {
    this.setState({ sharedByEmail: email });
    this.setState({ sharedByTimestamp: timestamp });
    this.setState({ showInfoDialog: true });
  };

  handleInfoDialogClose = () => {
    this.setState({ showInfoDialog: false });
    this.setState({ sharedByEmail: "" });
    this.setState({ sharedByTimestamp: "" });
  };

  handleShowDialogEnable = (infoText) =>{
    this.setState({infoText:infoText})
    this.setState({showDialog:true})
  }
  handleShowDialogDisable = () =>{
    this.setState({showDialog:false})
  }

  renderListingDetails = () => {
    const {
      classes,
      listingId,
      listingDetails: { loading, error, data, refetch },
      disableActions
    } = this.props;

    if (loading) {
      return <Loading />;
    }

    if (error) {
      return <Error message={error.message} />;
    }

    if (data) {
      const { vendors, websites, agents } = data;

      return (
        <React.Fragment>
          <ListingVendors
            vendors={vendors}
            disableActions={disableActions}
            onRemoveVendor={this.handleVendorRemoveDialogOpen}
            handleInfoDialog={this.handleInfoDialog}
          />
          <div className={classes.vendorListFooter}>
            <Divider />
            <Button
              disabled={disableActions}
              color="secondary"
              size="large"
              onClick={this.handleShareWithDialogOpen}
            >
              Share with vendor
            </Button>
          </div>
          {size(agents) > 0 && <ListingAgents agents={agents} />}
          {size(websites) > 0 && <ListingWebsites websites={websites} />}
          {this.state.showShareWithDialog && (
            <ListingShare
              listingId={listingId}
              handleClose={this.handleShareWithDialogClose}
              vendors={vendors}
              refetchVendor={refetch}
              handleShowDialogEnable={this.handleShowDialogEnable}
            />
          )}
          <ListingVendorRemove
            show={this.state.showVendorRemoveDialog}
            listingId={listingId}
            handleClose={this.handleVendorRemoveDialogClose}
            email={this.state.vendorEmailToRemove}
          />

          {this.state.showInfoDialog && (
            <InfoDialog
              onClose={this.handleInfoDialogClose}
              emailId={this.state.sharedByEmail}
              timeStamp={this.state.sharedByTimestamp}
              emailText="Email sent "
            />
          )}

          {
          this.state.showDialog &&
          <Dialog
                open={true}
                onClose={this.handleShowDialogDisable}
                fullWidth
                className={classes.infoDialog}
              >
          <div className={classes.infoDataDialog}>
            <IconButton onClick={this.handleShowDialogDisable} >
                    <CloseIcon />
            </IconButton>
            <Typography> {this.state.infoText} </Typography>
          </div>
          </Dialog>
          }
        </React.Fragment>
      );
    }

    return null;
  };

  render() {
    const {
      advertHeadingText,
      classes,
      statusDate,
      photoPrimaryUrl,
      priceDisplay,
      propertyAddress: { state, street, suburb },
      statusText
    } = this.props;

    return (
      <div className={classes.listingInfo}>
        <div
          className={classes.listingPhoto}
          style={{
            backgroundImage: `url(${photoPrimaryUrl || DEFAULT_LISTING_IMAGE})`
          }}
        />
        <Grid
          className={classes.listingTitleContainer}
          container
          alignItems="center"
        >
          <Grid item xs={12} md={6}>
            <Typography variant="h5">{advertHeadingText}</Typography>
            <Typography
              component="p"
              color="textSecondary"
            >{`${street} ${suburb}, ${state}`}</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography className={classes.listingStatus} variant="h6">
              {priceDisplay}
            </Typography>
            <Typography
              className={classes.listingStatus}
              component="p"
              color="textSecondary"
            >{`${statusText} on ${statusDate}`}</Typography>
          </Grid>
        </Grid>
        <Divider />
        <div className={classes.listingDetailContainer}>
          {this.renderListingDetails()}
        </div>
      </div>
    );
  }
}

const graphqlQueryGetListingDetailsById = graphql(queryGetListingDetailsById, {
  name: "listingDetails",
  options: ({ listingId }) => {
    return {
      fetchPolicy: "cache-and-network",
      variables: { listingId }
    };
  },
  props: ({ listingDetails }) => ({
    listingDetails: {
      data:
        listingDetails.loading && listingDetails.networkStatus === 1
          ? {}
          : listingDetails.getListingDetailsById,
      error: listingDetails.error,
      loading: listingDetails.loading && listingDetails.networkStatus === 1 || listingDetails.networkStatus === 4,
      refetch: listingDetails.refetch
    }
  })
});

export default flowRight(graphqlQueryGetListingDetailsById)(
  withStyles(styles)(ListingInfo)
);
