import React,{ useEffect } from "react";
import {
  Grid,
  Link,
  Typography,
  Menu,
  MenuItem,
} from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import withStyles from "@material-ui/core/styles/withStyles";
import styles from "./styles";

const StatusFilterComponent = ({
  listingStatuses,
  classes,
  getStatus,
  selectedItems,
  handleSelectedItems,
  itemsInMenu, 
  setItemsInMenu
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const selectItem = item => {
    handleClose();
    
      var tempItems = selectedItems;
      if (selectedItems.indexOf(item) === -1) {
        tempItems.push(item);
        handleSelectedItems(tempItems.sort());
        //  Removing element from itemsInMenu

        var itemsInMenuTemp = itemsInMenu;
          itemsInMenuTemp.splice(itemsInMenu.indexOf(item), 1);
        setItemsInMenu(itemsInMenuTemp.sort());
      getStatus(selectedItems);
    }
  };

     useEffect(() => {
        if (selectedItems.length === listingStatuses.length) {
           document.getElementById("add-status-link")
           .classList.add(classes.disabledLink);
        } else {
        document.getElementById("add-status-link")
        .classList.remove(classes.disabledLink);
        }
    });


  return (
    <Grid style={{ width: "100%" }}>
      <Grid item xs={12} md={3} className={classes.statusLink}>
        <Link
          id="add-status-link"
          component="button"
          variant="body2"
          aria-controls="statuses-menu"
          aria-haspopup="true"
          onClick={handleClick}
          focus="none"
        >
          <Typography id="add-status" color="textSecondary" variant="subtitle2">
            {"Filter listing status"}<ExpandMoreIcon style={{margin: "0px 50px -7px 4px"}}/>
          </Typography>
        </Link>
        {itemsInMenu.length > 0 && (
          <Menu
            id="statuses-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {itemsInMenu.map(status => (
              <MenuItem
                key={status}
                onClick={() => {
                  selectItem(status);
                }}
              >
                {status}
              </MenuItem>
            ))}
          </Menu>
        )}
      </Grid>
    </Grid>
  );
};
export default withStyles(styles)(StatusFilterComponent);
