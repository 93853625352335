"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _default = function _default(theme) {
  return {
    headerContainer: {
      padding: theme.spacing(3)
    },
    contentContainer: {
      padding: theme.spacing(2)
    },
    headerDivider: {
      marginTop: theme.spacing(3)
    },
    pageWrapper: {
      minHeight: "calc(100vh - 98px)"
    }
  };
};

exports["default"] = _default;