"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _default = function _default() {
  return {
    errorBoundary: {
      height: "100%"
    }
  };
};

exports["default"] = _default;