import React, { useContext } from "react";
import { useQuery } from "react-apollo-hooks";

import { get } from "lodash";
import queryGetListings from "../graphql/queryGetListings";
import AuthContext from "@clients-modules/react/lib/contexts/AuthContext";
import { SCOPE_LISTINGS } from "@clients-sites/ljh-platform/src/constants/index";

const ListingsContext = React.createContext({});

const ListingsContextProvider = ({ children }) => {
  const { currentScope } = useContext(AuthContext);
  const { data, error, loading } = useQuery(queryGetListings, {
    fetchPolicy: "cache-and-network",
    skip: !currentScope || !currentScope.includes(SCOPE_LISTINGS)
  });

  const listings = get(data, "getListings", {});

  const getListingById = listingId => {
    if (loading || error) {
      return null;
    }
    return listings.find(x => x.listingId === listingId);
  };

  return (
    <ListingsContext.Provider
      value={{
        error,
        getListingById,
        count: listings ? listings.length : 0,
        listings,
        loading
      }}
    >
      {children}
    </ListingsContext.Provider>
  );
};

export default ListingsContext;
export { ListingsContextProvider };
