"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _default = function _default(theme) {
  return {
    appBar: {
      top: "auto",
      bottom: 0,
      height: theme.spacing(4)
    },
    appToolBar: {
      minHeight: theme.spacing(4)
    }
  };
};

exports["default"] = _default;