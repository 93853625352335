export default theme => ({
    ActionExpTile: {
        paddingLeft: 0,
        paddingRight: 15,
        borderBottom: "1px solid #E0E0E0",
        "& .MuiExpansionPanelSummary-content": {
            margin: 0,
            [theme.breakpoints.up("sm")]: {
                overflow: "hidden"
            }
        },
        "& .MuiExpansionPanelSummary-expandIcon": {
            [theme.breakpoints.down("xs")]: {
                marginTop: 75,
                marginRight: -10
            }
        },
        "& .MuiIconButton-root": {
            [theme.breakpoints.down("xs")]: {
                marginLeft: -5
            }

        }
    },
    actionBtns: {
        padding: 10,
        position: "relative",
        float: "right",
        [theme.breakpoints.between('xs', 'sm')]: {
            padding: "0 10px 0 50px"
        },
        "& div": {
            "textAlign": "center",
            "& .MuiCardActions-root": {
                float: "right",
                [theme.breakpoints.between('sm', 'md')]: {
                    padding: 0
                }
            }
        },
        "& span": {
            verticalAlign: "top",
            [theme.breakpoints.down("md")]: {
                paddingLeft: 18
            },
            [theme.breakpoints.between('sm', 'md')]: {
                paddingLeft: 0
            }
        },
    },
    actionBtnsSingle: {
        paddingLeft: "8px !important"
    },
    actionText: {
        overflow: "hidden",
        color: "#525252",
        [theme.breakpoints.down("sm")]: {
            padding: "0 10px 0 50px",
        },
        [theme.breakpoints.down("xs")]: {
            padding: "0 0 0 45px",
            width: "95%",
            "& p": {
                margin: 0
            }

        },

    },
    actionTextSingle: {
        paddingLeft: "10px"
    },
    actionTitleAndBtns: {
        position: "relative",
        width: "100%",
        [theme.breakpoints.down("xs")]: {
            padding: "3px !important"
        },
        "& .MuiSvgIcon-root": {
            paddingBottom: "2px !important"

        }
    },
    actionTitleAndBtnsMobile: {
        margin: 10
    },
    actionTitle : {
        padding:9,
    },
    actionTitleSingleCard : {
            [theme.breakpoints.up("md")] : {
                marginLeft : 43
            }
    },
    expansionPanelDetails: {
        padding: "0 !important",
        "& .MuiExpansionPanelDetails-root": {
            padding: 5,
        }
    },
    expansionPanelDetailsMobile: {
        padding: "0px 10px 0px !important"
    },
    actionCountSection: {
        width: "30px",
        height: "30px",
        top: "30%",
        background: "red",
        color: "white",
        float: "right",
        [theme.breakpoints.down("xs")]: {
            marginRight: "-48px"
        }
    },
    listingCardContainer: {
        marginBottom: "-5px",
    },
    listingInfo: {
        margin: "auto"
    },
    listingsExpPanel: {
        border: "1px solid #E0E0E0",
        boxShadow: "none",
        borderRadius: 5,
        [theme.breakpoints.down("xs")]: {

            "boxShadow": "0px 1px 6px rgba(0, 0, 0, 0.25)",
            "borderRadius": "4px"
        }
    },
    actionSeparator: {
        width: "100%",
        border: "0 solid #e0e0e0",
        height: "1px"
    },
    checkboxAlignment: {
        padding: "0 5px",
        "& .MuiTypography-body1": {
            paddingLeft: "6px"
        }
    },
    suggestedCards: {
        background: "#f9f9f9"
    },
    prevCardTitle: {
        padding: "16px"
    },
    prevCardContent: {
        marginTop: "-10px",
        padding: "8px 52px",
        maxWidth: 404,
        [theme.breakpoints.down('sm')]: {
            padding: "16px"
        }
    },
    previewBtn: {
        color: "#979797",
        cursor: "pointer",
        "& .MuiSvgIcon-root": {
            padding: "0 0 3px",
            float: "right",
            margin: "15px 15px 0 22px",
            [theme.breakpoints.between('sm', 'md')]: {
                float: "unset",
                padding: "0 0 5px",
                margin: "8px 10px 0 0",

            },
        },
    },
    prevCard: {
        [theme.breakpoints.down('sm')]: {
            "& .MuiDialog-paper": {
                margin: 8
            }
        }
    },
    desktopView: {
        display: "flex",
        width: "100%",
        [theme.breakpoints.down("xs")]: {
            display: "none"
        }
    },
    mobileView: {
        width: "100%",
        [theme.breakpoints.up("sm")]: {
            display: "none"
        }
    },
    selectAllBtn: {
        width: "100%",
        "& .MuiFormControlLabel-root": {
            [theme.breakpoints.down("xs")]: {
                marginLeft: "3px !important"
            }
        },
        "& .MuiSvgIcon-root": {
            paddingBottom: "2px !important"

        },
        "& .MuiCardActions-root": {
            padding: 3,
            [theme.breakpoints.down("xs")]: {
                float: "right",
            },
            "& .MuiButton-text": {
                padding: "5px 8px",
                [theme.breakpoints.down("xs")]: {
                    padding: "6px 8px"
                }
            }
        }
    },
    VisibilityIconMobile: {
        float: "Right",
        margin: "8px 10px",
        color: "#979797"
    },
    VisibilityIconMobileSingle: {
        margin: "0 2px",
    },
    standardLinks: {
        float: "right",
        paddingRight: 0,
        margin: " 0 -10px"
    },
    actionsMobileView: {
        padding: 12,
        width: "100%"
    },
    propertyAddressAndDate: {
        fontSize: 14,
        color: "#747373"
    },
    contentInMiddle: {
        [theme.breakpoints.up("md")]: {
            width: "100%",
            position: "absolute",

            top: "50%",
            transform: "translate(0%,-50%)"
        }
    },
    listingImage: {
        height: "80px",
        width: "70%"
    }
});