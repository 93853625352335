export default theme => ({
  gridContainer: {
    padding: theme.spacing(),
    margin: "16px"
  },
  gridDivider: {
    margin: `${theme.spacing(3)}px 0`,
    width: "100%"
  },
  statusFilter: {
    padding: "5px"
  },
  disabledLink: {
    pointerEvents: "none",
    backgroundColor: "#fafafa"
  },
  gridSpan: {
    color: theme.palette.grey[600],
    marginLeft: "4px"
  },
  gridButton: {
    color: theme.palette.grey[600],
    marginRight: "10px"
  },

  statusGrid: {
  flexDirection : "row",
    color: "#696969"
  },
  statusLink: {
    float: "right",
    maxWidth: "100% !important",
    padding: "8px 0",
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    },

    "& .MuiLink-underlineHover:hover":{
      textDecoration : "none"
    }
  },

  statusGridList: {
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0"
    }
  },
  clearIcon: {
    padding: 4
  },
  statusBlock: {
    flexDirection : "row",
    margin: "-19px 0 5px 0"
  }
});
