"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _colorManipulator = require("@material-ui/core/styles/colorManipulator");

var _handlebars = require("handlebars");

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

var _default = function _default(theme) {
  var _titleLogo, _theme$breakpoints$do, _pageLink;

  return {
    root: {
      width: "100%"
    },
    grow: {
      flexGrow: 1
    },
    menuButton: {
      marginLeft: -12,
      marginRight: 20
    },
    title: _defineProperty({
      display: "none"
    }, theme.breakpoints.up("sm"), {
      display: "block"
    }),
    titleLogo: (_titleLogo = {
      display: "block",
      verticalAlign: "center"
    }, _defineProperty(_titleLogo, theme.breakpoints.up("md"), {
      marginLeft: '20px'
    }), _defineProperty(_titleLogo, theme.breakpoints.between('sm', 'md'), {
      position: "initial",
      marginLeft: '20px'
    }), _defineProperty(_titleLogo, theme.breakpoints.up("sm"), {
      margin: "8px 16px 0 22px"
    }), _titleLogo),
    titleLogoImage: _defineProperty({
      height: theme.spacing(4),
      margin: "auto"
    }, theme.breakpoints.down("xs"), (_theme$breakpoints$do = {
      height: theme.spacing(3)
    }, _defineProperty(_theme$breakpoints$do, "height", "24px"), _defineProperty(_theme$breakpoints$do, "position", "absolute"), _defineProperty(_theme$breakpoints$do, "left", 0), _defineProperty(_theme$breakpoints$do, "right", 0), _defineProperty(_theme$breakpoints$do, "top", 0), _defineProperty(_theme$breakpoints$do, "bottom", 0), _theme$breakpoints$do)),
    pageLinkContainer: _defineProperty({
      cursor: "pointer",
      textDecoration: "none",
      padding: "0 ".concat(theme.spacing(2), "px"),
      borderBottom: "5px solid transparent",
      "&:hover, &.active": {
        borderColor: theme.palette.secondary.main
      }
    }, theme.breakpoints.down("xs"), {
      padding: "0 ".concat(theme.spacing(1.5), "px"),
      display: "none"
    }),
    pageLink: (_pageLink = {
      color: theme.palette.common.white,
      textDecoration: "none"
    }, _defineProperty(_pageLink, theme.breakpoints.down("sm"), {
      marginLeft: theme.spacing()
    }), _defineProperty(_pageLink, "lineHeight", "62px"), _pageLink),
    search: _defineProperty({
      position: "relative",
      borderRadius: theme.shape.borderRadius,
      backgroundColor: (0, _colorManipulator.fade)(theme.palette.common.white, 0.15),
      "&:hover": {
        backgroundColor: (0, _colorManipulator.fade)(theme.palette.common.white, 0.25)
      },
      marginRight: theme.spacing(2),
      marginLeft: 0,
      width: "100%"
    }, theme.breakpoints.up("sm"), {
      marginLeft: theme.spacing(3),
      width: "auto"
    }),
    searchIcon: {
      width: theme.spacing(9),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    },
    inputRoot: {
      color: "inherit",
      width: "100%"
    },
    inputInput: _defineProperty({
      paddingTop: theme.spacing(),
      paddingRight: theme.spacing(),
      paddingBottom: theme.spacing(),
      paddingLeft: theme.spacing(10),
      transition: theme.transitions.create("width"),
      width: "100%"
    }, theme.breakpoints.up("md"), {
      width: 200
    }),
    officeMenuItem: _defineProperty({}, theme.breakpoints.down("sm"), {
      display: "none"
    }),
    dialogContent: {
      minWidth: "400px",
      minHeight: "150px"
    },
    listingsBadge: {
      top: "-15px",
      right: -5 // [theme.breakpoints.down("sm")]: {
      //   right: "72vw"
      // }

    },
    profileMenu: {
      border: "1px solid #d3d4d5"
    },
    profileIcon: _defineProperty({
      display: "none"
    }, theme.breakpoints.down("sm"), {
      display: "inline-block"
    }),
    officeName: _defineProperty({}, theme.breakpoints.down("sm"), {
      display: "none"
    }),
    officeSwitcherIcon: _defineProperty({
      marginTop: "7px",
      marginLeft: "2px",
      transition: "all 200ms"
    }, theme.breakpoints.down("sm"), {
      display: "none"
    }),
    expandOpen: {
      transform: "rotate(180deg)",
      marginLeft: "2px",
      transition: "all 200ms"
    },
    menuIcon: _defineProperty({
      display: "block",
      marginLeft: theme.spacing(2),
      cursor: "pointer",
      margin: "16px 0px"
    }, theme.breakpoints.up("sm"), {
      display: "none"
    }),
    HamburgerMenuSection: _defineProperty({
      position: "absolute",
      zIndex: 1000,
      width: "100vw",
      height: "90vh",
      borderTop: "1px solid #484848",
      top: "100%",
      bottom: 0,
      "& h6": {
        borderBottom: "1px solid #484848",
        paddingLeft: theme.spacing(2) + 1,
        marginLeft: 0,
        backgroundColor: "#161616"
      }
    }, theme.breakpoints.up("sm"), {
      display: "none !important"
    }),
    pageLinkContainerMobile: {
      textDecoration: "none"
    }
  };
};

exports["default"] = _default;