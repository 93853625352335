import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { orderBy as _orderBy, map } from "lodash";
import moment from "moment";
import {
  Chip,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableSortLabel,
  Switch,
  Tooltip,
  IconButton,
  withStyles,
  Typography  
} from "@material-ui/core";
import { OpenInNew, Delete } from "@material-ui/icons";

import styles from "./styles";

//'Date', 'Name', 'Created by', 'Published'
const labels = {
  eventTimestamp: "Date",
  Filename: "Name",
  CreatedBy: "Created by",
  published: "Published"
};

function DocumentTable({
  classes,
  documents,
  onPublishChange,
  onDownloadClick,
  onDeleteClick,
  disableActions
}) {
  const [orderBy, setOrderBy] = useState("eventTimestamp");
  const [order, setOrder] = useState("desc");
  const [orderDocuments, setOrderDocuments] = useState([]);
  const handleSort = ob => () => {
    const isDesc = orderBy === ob && order === "desc";
    setOrder(isDesc ? "asc" : "desc");
    setOrderBy(ob);
  };

  useEffect(() => {
    setOrderDocuments(_orderBy(documents, [orderBy], [order]));
  }, [documents, order, orderBy]);

  return (
    <Table className={classes.table}>
      <TableHead>
        <TableRow>
          {map(labels, (v, k) => (
            <TableCell
              key={k}
              sortDirection={orderBy === k ? order : false}
              padding={k === "published" ? "checkbox" : "default"}
            >
              <TableSortLabel
                active={orderBy === k}
                direction={order}
                onClick={handleSort(k)}
              >
                {v}
              </TableSortLabel>
            </TableCell>
          ))}
          <TableCell padding="none" />
        </TableRow>
      </TableHead>
      <TableBody>
        {map(orderDocuments, d => {
          const {
            documentId,
            filename,
            eventTimestamp,
            createdByEmail,
            published,
            sourceCrm
          } = d;
          var tooltip = "";
          if(sourceCrm && published){
            tooltip = "This document is visible to Vendors and has been synced from Move2. "
          }else if (sourceCrm && !published){
            tooltip = "This document is not visible to Vendors, update document visibility in Move2."
          }else if (!sourceCrm && published){
            tooltip = "This document has been published and is visible to Vendors."
          }else {
            tooltip = "This document has not been published and is not visible to Vendors."
          }
          return (
            <TableRow key={documentId}>
              <TableCell padding="none" style={{ paddingLeft: 16, width: 16 }}>
                <Typography noWrap>
                  {moment(eventTimestamp).format("DD MMM YY")}
                </Typography>
              </TableCell>
              <TableCell style={{ maxWidth: '20%' }}>
                <Tooltip classes={{ tooltip: classes.tooltipWidth }} title={filename}>
                  <Typography className={classes.documentName} noWrap>{filename}</Typography>
                </Tooltip>
              </TableCell>
              <TableCell style={{ maxWidth: 0 }}>
              { !sourceCrm && 
                  <Tooltip title={createdByEmail}>
                    <Typography noWrap>{createdByEmail}</Typography>
                  </Tooltip>
              }
              { sourceCrm && <Chip  label="CRM"></Chip> }
              </TableCell>
              <TableCell padding="none">
                <Tooltip title={tooltip}>
                <div>
                  <Switch
                      color="default"
                      checked={published}
                      onChange={onPublishChange(d)}
                      disabled={disableActions} 
                      className= { sourceCrm ? classes.toggleButton : ""}
                    />
                </div>
                </Tooltip>
              </TableCell>
              <TableCell padding="checkbox">
                <div className={classes.flexAlignCenter}>
                  <Tooltip title="Download">
                    <IconButton onClick={onDownloadClick(d)}>
                      <OpenInNew />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete">
                    <IconButton
                      onClick={onDeleteClick(d)}
                      disabled={sourceCrm ? "disabled" :  disableActions ? disableActions : ""}                     >
                      <Delete />
                    </IconButton>
                  </Tooltip>
                </div>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
}

DocumentTable.propTypes = {
  classes: PropTypes.object.isRequired,
  documents: PropTypes.array,
  onPublishChange: PropTypes.func.isRequired,
  onDownloadClick: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired
};

export default withStyles(styles)(DocumentTable);
