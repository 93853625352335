import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Typography, Checkbox, Dialog, Divider, DialogContent, DialogActions, DialogTitle, Button } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ActivityCard from "../../../../components/ActivityCard";
import StandardCardLinks from '../../../../components/ActivityCard/StandardCardLinks'
import ActionsContext from "../../../../contexts/ActionsContext"
import styles from "./styles";

const NewActionCard = ({ classes, actions, expPanel, isExpanded }) => {

    const [check, setChecked] = useState(false);
    const [checkedCount, setCheckedCount] = useState(0);
    const [updatableActions, setUpdatableActions] = useState([]);
    const [showPreview, setShowPreview] = useState(false);
    const [previewAction, setPreviewAction] = useState({});
    const { uncheckSelectedActions } = useContext(ActionsContext);

    var hasMultipleActions = actions && actions.length>1;
    const handlePreview = (actn) => {
        setShowPreview(true);
        setPreviewAction(actn);
    }
    const handleClose = () => {
        setShowPreview(false);
    }
    var pendingActionElements = document.querySelectorAll("#" + expPanel + ' input[type=checkbox]');
    const checkMultiple = (e) => {
        if (e.target.checked) {
            setCheckedCount(checkedCount + 1);
            if (updatableActions.length > 0) {
                var publishableObj = updatableActions.filter(pubObj => pubObj.id === actions[e.target.value].id);
                if (publishableObj.length === 0)
                    setUpdatableActions([...updatableActions, actions[e.target.value]]);
            }
            else
                setUpdatableActions([actions[e.target.value]]);
        }
        else {
            if(updatableActions.length>0){
            var updatableActionsList = updatableActions.filter(action => action.id !== actions[e.target.value].id);
            setUpdatableActions(updatableActionsList);
            }
            if(checkedCount>0)
                setCheckedCount(checkedCount - 1);
        }
    }

    const handleCheck = () => {
        for (var i = 1; i < pendingActionElements.length; i++) {
            if (!check) {
                if (!pendingActionElements[i].checked) {
                    pendingActionElements[i].click();
                }
            } else {
                pendingActionElements[i].click();
            }
        }
        if (!check) {
            setCheckedCount(actions.length);
            setUpdatableActions([...actions]);
        } else {
            setCheckedCount(0)
        }

        //  setChecked(!check);
    }

    const uncheckActions = () => {
        for (var i = 0; i < pendingActionElements.length; i++) {
            if (pendingActionElements[i].checked) {
                pendingActionElements[i].click();
            }
        }
    }
    if (!isExpanded || uncheckSelectedActions) {
        if (checkedCount > 0)
        {
        setCheckedCount(0); 
        setUpdatableActions([]);         
        uncheckActions();
        }
    }

    const cardContentHandler = (text) => {
        var maxLength = 100;
        var newStr = text.substring(0, maxLength);
        return (<p>
            {newStr}
            <span><b> . . .</b></span>
        </p>);
    }

    useEffect(() => {
        if (checkedCount === actions.length) {
            setChecked(true);
        } else {
            setChecked(false);
        }
    }, [checkedCount, actions.length]);


    return (
        <Grid container direction="column">
{window.outerWidth>600 &&
             <Grid container className={classes.desktopView} direction="row">
                <Grid container className={classes.selectAllBtn} direction="row">
                    {
                       hasMultipleActions && 
                        <Grid item md={3} style={{ marginLeft: 10 }}>
                            <FormControlLabel
                                className={classes.checkboxAlignment}
                                control={
                                    <Checkbox checked={check} onChange={handleCheck} value="check" inputProps={{ 'aria-label': 'uncontrolled-checkbox' }} />
                                }
                                label="Select All"
                            />
                        </Grid>
                    }
                    {checkedCount > 1 &&
                        (
                            <Grid item md={8}>
                                <StandardCardLinks
                                    cardsData={updatableActions}
                                    publishable={true}
                                    hideable={true}
                                    links={[]}
                                />

                            </Grid>
                        )}
                </Grid>
                {
                    actions.map((action, actIndex) => {
                        return (
                            <Grid container direction="row" key={actIndex} className={classes.suggestedCards}>
                                {actions.length > 1 && <Divider className={classes.actionSeparator} />}
                                <Grid item md={4} className={classes.actionTitleAndBtns}>
                                <div className={classes.contentInMiddle}>
                                {
                                    hasMultipleActions &&
                                    <span style={{ float: "left" }} className={classes.checkboxAlignment}><Checkbox onChange={checkMultiple} value={actIndex} inputProps={{ 'aria-label': 'uncontrolled-checkbox' }} /></span>
                                }
                                    <span ><Typography className={ hasMultipleActions ? classes.actionTitle : [classes.actionTitle,classes.actionTitleSingleCard].join(" ")}>{action.title}</Typography></span>
                                
                                </div>
                                </Grid>
                                <Grid item md={4} className={ hasMultipleActions ? classes.actionText : [classes.actionTextSingle,classes.actionText].join(" ")}>
                                    {
                                        (action.content[0].text && action.content[0].text.length > 130) ? cardContentHandler(action.content[0].text) : (action.content[0].text ? (<p>{action.content[0].text.replace(/\\n/g, '\n')}</p>) : '')
                                    }
                                </Grid>
                                <Grid item md={4} className={ hasMultipleActions ? classes.actionBtns : [classes.actionBtns,classes.actionBtnsSingle].join(" ")}>
                                    <div className={classes.contentInMiddle}>
                                        <StandardCardLinks
                                            cardsData={[action]}
                                            publishable={true}
                                            hideable={true}
                                            links={[]}
                                        />
                                        <span className={classes.previewBtn} onClick={() => handlePreview(action)}><VisibilityIcon/></span>

                                    </div>
                                </Grid>
                            </Grid>
                        )
                    })
                }
            </Grid>
}
{/* Mobile view */}
{window.outerWidth<600 &&
             <Grid container className={classes.mobileView} direction="row">
                <Grid className={classes.selectAllBtn}>
                {
                        hasMultipleActions &&
                    <FormControlLabel 
                        control={
                            <Checkbox checked={check} onChange={handleCheck} value="check" inputProps={{ 'aria-label': 'uncontrolled-checkbox' }} />
                        }
                        label="Select All"
                    />
                }
                {
                    checkedCount > 1 &&
                                <StandardCardLinks cardsData={actions} hideable={true} publishable={true} view="mobile" links={[]}/>
                }
                </Grid>
                {
                    actions.map((action,actIndex) => (
                <Grid className={classes.suggestedCards} key={actIndex}>
                    <Divider className={classes.actionSeparator}/>
                     <Grid item className={ hasMultipleActions ? classes.actionTitleAndBtns : classes.actionTitleAndBtnsMobile}>
                                {
                                    hasMultipleActions && 
                                <span><Checkbox onChange={checkMultiple} value={actIndex} inputProps={{ 'aria-label': 'uncontrolled-checkbox' }} /></span>
                                }
                                <span><Typography style={{ display: "inline" }}>{action.title}</Typography></span>
                                <span className={ hasMultipleActions ? classes.VisibilityIconMobile : [classes.VisibilityIconMobile,classes.VisibilityIconMobileSingle].join(" ")} onClick ={()=>handlePreview(action)}><VisibilityIcon /></span>
                     </Grid>
                     <Grid item >
                         <div className={ hasMultipleActions ? classes.actionText : [classes.actionTextSingle,classes.actionText].join(" ")}>
                                {
                                (action.content[0].text && action.content[0].text.length > 130) ? cardContentHandler(action.content[0].text) : (action.content[0].text ? (<p>{action.content[0].text.replace(/\\n/g, '\n')}</p>) : '')
                                }
                                </div>
                     </Grid>
                     <Grid item className={classes.standardLinks} >
                     <StandardCardLinks cardsData={[action]} hideable={action.hideable} publishable={true} links={[]}/>
                     </Grid>
                </Grid>
                )
                )}

            </Grid>
}
            {showPreview &&
                <Dialog onClose={handleClose} className={classes.prevCard} aria-labelledby="preview-dialog-title" open={showPreview}>
                    <DialogTitle className={classes.prevCardTitle} id="preview-dialog-title">Preview Card</DialogTitle>
                    <DialogContent className={classes.prevCardContent}>
                        <ActivityCard
                            {...previewAction}
                            activityId={previewAction.key}
                            publishable={false}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} style={{ color: "red" }}>
                            Close
                    </Button>
                    </DialogActions>
                </Dialog>}
        </Grid>
    );
}
NewActionCard.propTypes = {
    actions: PropTypes.array,
    classes: PropTypes.object,
    expPanel: PropTypes.string
};
export default withStyles(styles)(NewActionCard);